.twoButtonContainer{
    width: 460px;
    height: auto;
    border: 1px solid #00000030;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.twoButton{
    width: 200px;
    margin: 10px;
    height: 70px;
    background-color: #3C4096;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white
}


.twoButton_inactive{
    width: 200px;
    margin: 10px;
    height: 70px;
    background-color: #3C409620;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:	#3C4096
}

.twoButton img {
    margin-right: 20px;
}

.twoButton_inactive img {
    margin-right: 20px;
}

.tabBar{
    display: flex;
    flex-direction: column;
    width: 460px;
    justify-content: space-around;
    align-items: center;
}

.tabBar_tab{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #3C4096;
    border-width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    flex-direction: column;
    border-color: tomato;
    width: 50%;
    height: 70px;
}

.tabBar_tab_inactive{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #3C409620;
    border-width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    flex-direction: column;
    border-color: tomato;
    width: 40%;
    height: 70px;
    color:#3C4096
}

.navigationButtons_back_disabled{
    background-color: #e06f84;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border: none !important;
    margin-left: 20px;
    color: white;
    font-weight: 700;
    outline: none !important;
}

.tabBar_containers{
    max-width: 460px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: auto;
    padding: 10px;
    background-color: #3C4096;
}

.tabBar_container{
    background-color: white;
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
}

.navigationButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 80px;
}

.navigationButtons_back{
    background-color: #E33252;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border: none !important;
    margin-left: 20px;
    color: white;
    font-weight: 700;
    outline: none !important;
}

.navigationButtons_next{
    background-color: #3C4096;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border: none !important;
    margin-right: 20px;
    color: white;
    font-weight: 700;
    outline: none !important;
}


.navigationButtons_next:hover{
    background-color: #FBD105;
}

.navigationButtons_pay{
    background-color: #1E9848;
    border-radius: 10px;
    width: 130px;
    height: 40px;
    border-radius: 10px;
    border: none !important;
    margin-right: 20px;
    color: white;
    font-weight: 700;
    outline: none !important;
}

.navigationButtons_pay img {
    width: 15px;
    margin-right: 10px;
}


.infoContainer{
    width: 460px;
    height: auto;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #00000030;
}


.summary_container{
    max-width: 460px;
    width: 100%;
    height: auto;
    border: 1px solid #00000010;
    border-radius: 5px;
    padding: 20px;
}


@media screen and (max-width: 700px) {
    .twoButtonContainer{
        width: 90%;
    }

    .twoButton_inactive img {
        margin-right: 10px;
        margin-left: 10px;
    }

    .twoButton img {
        margin-right: 10px;
        margin-left: 10px;
    }

    .twoButton_inactive p {
        margin-right: 10px !important;
        font-size: 14px;
    }

    .twoButton p {
        margin-right: 10px !important;
        font-size: 14px;
    }
    
    .tabBar{
        width: 90%;
    }

    .tabBar_tab{
        width: 20%;
    }

    .tabBar_tab_inactive{
        width: 20%;
    }

    .infoContainer{
        width: 90%;
    }
}