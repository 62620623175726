.main {
    border-radius: 10px !important;
    box-shadow: none !important;
    background: #f9f9f9 !important;
}

.title {
    border-radius: 10px !important;
}

.title>div>p {
    font-weight: 600;
    /* color: var(--secondary); */
    color:#2f2e80;
}